import React from "react";
import { useState} from "react";
import axios from "axios";
import './Login.css';
import loginPic from '../../SharedAssets/loginPic.png';
import logo from '../../SharedAssets/logo.png';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { RoundGreenButton } from '../CoreComponents/CustomButton/RoundGreenButton';
import { RoundWhiteButton } from '../CoreComponents/CustomButton/RoundWhiteButton';
import { passwordColorChanges } from "../CoreStructure/Drawer/Shared/Misc";
import { setToken } from "../../Utilities/auth/auth";
import { API_ENDPOINT } from "../../api/index"

import { Checkbox } from "@mui/material";
import { checkboxColorChanges } from "./../CoreStructure/Drawer/Shared/Misc";

import { usePostHog } from 'posthog-js/react'


export const Login = (props) => {
    const [values, setValues] = useState({
        email: '',
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });

    const [checkbox, setCheckbox] = useState(false);

    const [screen, setScreen] = useState('login');
    const [isError, setIsError] = useState(false);

    const posthog = usePostHog();

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changeScreen = (scr) => {
        setIsError(false);
        setScreen(scr)
    }

    const handleError = (err) => {
        if (err && err.response && err.response.status >= 400) {
            setIsError(true);
            setValues((prevState) => ({
                ...prevState,
                message: err.response.data[0]
            }));
        } else {
            console.log(err);
            setValues((prevState) => ({
                ...prevState,
                message: "an unknown error occured please try again later",
            }));
        }
    };

    const authenticateUser = async () => {

        const data = {
            email: values.email,
            password: values.password,
        };

        try {
            const response = await axios.post(`${API_ENDPOINT}/auth/login`, {
                data,
            });

            if (response.data && response.data.success) {
                setToken(response);
                posthog?.identify(values.email, {
                    email: values.email, user_id: response.id
                })
                window.location = "/";
            }
        } catch (err) {
            handleError(err);
        }
    };

    const registerUser = async () => {

        const data = {
            email: values.email,
            password: values.password,
        };

        try {
            const response = await axios.post(`${API_ENDPOINT}/auth/register`, {
                data,
            });

            if (response.data.success) {
                setToken(response);
                posthog?.identify(values.email, {
                    email: values.email, user_id: response.id
                })
                window.location = "/";
            }
        } catch (err) {
            handleError(err);
        }
    };

    const breakpoint = props.breakpoint;

let handleChangeCheckbox = () => {
    setCheckbox(!checkbox);
}


    return screen === 'forgot' ? <Forgot breakpoint={breakpoint} width={props.width} changeScreen={changeScreen}/> : (
        <div className='top'>
            {props.width > breakpoint ? <LeftSideArtwork/> : ''}
            <div className={props.width > breakpoint ? 'col' : 'colThin'}>
                <form onSubmit={(e) => {e.preventDefault()}}>
                    <div className='contentDiv widthControl'>
                        <img className='space' src={logo}></img>
                        <div className=' fields'>
                                <TextField
                                id="standard-basic"
                                label="Email"
                                variant="standard"
                                value={values.email}
                                onChange={handleChange('email')}
                                fullWidth
                                sx={passwordColorChanges}
                                error={isError}
                                helperText={isError && screen==='create' ? 'Please choose a different email address' : isError && screen==='login' ? 'Try again - email and password do not match' : ''}
                            />
                        </div>

                        <div className='space fields'>
                            <FormControl variant="standard" sx={passwordColorChanges} fullWidth >
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input

                                    id="standard-adornment-password"
                                    label="Password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    error={screen==='login' ? isError : false}
                                />
                            </FormControl>
                        </div>
                        

                        {screen === 'create' ?
                            <div>
                                <div className='termsRow'>
                                    <Checkbox 
                                        sx={checkboxColorChanges}
                                        onChange={()=>handleChangeCheckbox()}
                                        style={{ padding: 0 }}
                                    />
                                    <p className={'secondaryLabelGray leftMargin'}>I agree to the </p>
                                    <a href="https://www.wayboz.com/privacy-policy" target="_blank" rel="noreferrer" className={'secondaryLabelGray leftMargin'} > Terms of Service</a>
                                </div>
                                <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                                    {!values.email || !values.password || !checkbox ? <RoundGreenButton className='space' disabled >CREATE ACCOUNT</RoundGreenButton> : <RoundGreenButton onClick={registerUser} className='space' type='submit'>CREATE ACCOUNT</RoundGreenButton>}
                                    <p className='exSpace space secondaryLabelGreen'>Already have an account?</p>
                                    <RoundWhiteButton className='space' onClick={() => changeScreen('login')} >SIGN IN</RoundWhiteButton>
                                </div>
                            </div>
                            :
                            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                                {!values.email || !values.password ? <RoundGreenButton className='space' disabled >LOGIN</RoundGreenButton> : <RoundGreenButton onClick={authenticateUser} className='space' type='submit' >LOGIN</RoundGreenButton>}
                                <p className='space secondaryLabelGreen' style={{cursor:'pointer'}} onClick={() => changeScreen('forgot')}>Forgot password?</p>
                                <RoundWhiteButton className='exSpace space' onClick={() => changeScreen('create')} >CREATE ACCOUNT</RoundWhiteButton>
                            </div>
                        }
                    </div>
                </form>
            </div>
        </div>
      )
};

const LeftSideArtwork = (props) => {
    return (
        <div className='col firstcol'>
            <div className='contentDiv'>
                <img className='space' src={loginPic}></img>
                <p className='space primaryLabel'>Welcome to WayBoz</p>
                <p className='space secondaryLabelGray'>Portfolio ROI tracking for real estate investors</p>
            </div>
        </div>
    )
}

const Forgot = (props) => {


    const [values, setValues] = useState({
        email: '',
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });

    const [isError, setIsError] = useState(false);
    const [screen, setScreen] = useState('');

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleError = (err) => {
        if (err && err.response && err.response.status >= 400) {
            setIsError(true);
            setValues((prevState) => ({
                ...prevState,
                message: err.response.data[0]
            }));
        } else {
            console.log(err);
            setValues((prevState) => ({
                ...prevState,
                message: "an unknown error occured please try again later",
            }));
        }
    };

    const resetPassword = async () => {

        const data = {
            email: values.email
        };

        try {
            const response = await axios.post(`${API_ENDPOINT}/auth/reset-password`, {
                data,
            });

            if (response.data.success) {
                setScreen('password-reset-email-sent')
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <div className='top'>
            {props.width > props.breakpoint ? <LeftSideArtwork/> : ''}
            <div className={props.width > props.breakpoint ? 'col' : 'colThin'}>
                <form onSubmit={(e) => {e.preventDefault()}}>
                    <div className='contentDiv widthControl'>
                        <img className='space' src={logo}></img>
                        {screen === 'password-reset-email-sent' ?
                        <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                            <p style={{marginTop: "15px", width: "80%", fontWeight: "600", textAlign: "center"}} className='exSpace space secondaryLabelGreen'>Password reset email sent! Please check your email and follow the link to reset your password.</p>
                            {/* <RoundWhiteButton className='space' onClick={() => props.changeScreen('login')} >LOGIN</RoundWhiteButton> */}
                        </div>
                        :
                        <>
                            <div className=' fields'>
                                    <TextField
                                    id="standard-basic"
                                    label="Email"
                                    variant="standard"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    fullWidth
                                    sx={passwordColorChanges}
                                    error={isError}
                                    helperText={isError ? 'An error occurred. Please try again later.' : ''}
                                />
                            </div>

                            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                                {!values.email ? <RoundGreenButton className='space' disabled >RESET PASSWORD</RoundGreenButton> : <RoundGreenButton onClick={resetPassword} className='space' type='submit'>RESET PASSWORD</RoundGreenButton>}
                                <p className='exSpace space secondaryLabelGreen'>Already have an account?</p>
                                <RoundWhiteButton className='space' onClick={() => props.changeScreen('login')} >LOGIN</RoundWhiteButton>
                            </div>
                        </>}
                    </div>
                </form>
            </div>
        </div>
    )
}
